<template>
  <v-dialog
    v-model="value"
    max-width="30%"
    persistent
  >
    <v-card>
      <v-card-title class="headline">Create Fiware Service</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                dense
                label="Fiware Service Name *"
                v-model="fiwareService"
                counter
                :maxlength="50"
                :rules="commonRules"
                required
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="red darken-1"
          text
          :disabled="cancleDisable"
          @click="$emit('input', false), $refs.form.resetValidation()"
        >
          Cancle
        </v-btn>
        <v-btn
          small
          color="green darken-1"
          text
          :disabled="!fiwareService"
          @click="$emit('create', fiwareService), $emit('input', false), fiwareService = null"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewFiwareService',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    cancleDisable: {
      type: Boolean,
      default: true,
      require: false
    }
  },
  data: () => ({
    fiwareService: null,
    valid: true
  }),
  computed: {
    commonRules: function () {
      return [
        v => !!v || 'field is required',
        v => (v && v.length <= 50) || 'Max 50 characters'
      ]
    }
  },
  methods: {
  },
  created () {
  }
}
</script>
